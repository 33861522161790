import { APITypes } from "@stellar/api-logic";

/** Company features that require the Sphere Viewer as default app to open projects */
export const SPHERE_VIEWER_COMPANY_FEATURES = [
  APITypes.EUserSubscriptionRole.globalOrbisProcessing,
  APITypes.EUserSubscriptionRole.globalVideoMode,
];

/**
 * List of features that allow the 'open in HoloBuilder' button to be shown for a company.
 */
export const HOLO_BUILDER_OPEN_BUTTON_FEATURES: APITypes.EUserSubscriptionRole[] =
  [
    APITypes.EUserSubscriptionRole.openInWebeditor,
    // TODO: Remove this once we know that openInWebeditor is available in all HB plans
    "global-img360-write" as APITypes.EUserSubscriptionRole,
  ];
